<template>
	<div>
		<ContentHeader title="Tindakan" url="/tindakan" title2="Data Voucher" url2="/data-voucher" subTitle="Edit Data Voucher" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row">
								<h3 class="card-title">Penerbitan Voucher Promo</h3>
							</div>
							<div class="card-body">
								<form @submit.prevent="updateDataVoucher">
									<div class="row">
										<div class="col-10">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Kode Voucher <span class="text-danger">*</span></label>
								                   <input type="text" class="form-control" required="" id="kode_voucher" name="kode_voucher" placeholder="Kode Voucher" disabled="" v-model="voucher.kode_voucher">
									           </div>
											</div>	
										</div>
										<div class="col-2"></div>	
									</div>
									<div class="row">
										<div class="col">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Sejumlah <span class="text-danger">*</span></label>
								                   <div class="input-group mb-3">
								                      <input type="number" class="form-control" required="" id="jumlah" name="jumlah" placeholder="Jumlah" v-model="voucher.jumlah">
								                      <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Lembar</div>
									                  </div>
									               </div>
									           </div>
											</div>	
										</div>
										<div class="col"></div>	
									</div>
									<div class="row">
										<div class="col">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Nominal <span class="text-danger">*</span></label>
								                   <div class="input-group mb-3">
								                      <div class="input-group-prepend">
									                    <div class="btn btn-secondary">{{ labelNominal }}</div>
									                    <button type="button" class="btn btn-secondary btn-flat dropdown-toggle dropdown-icon" data-toggle="dropdown">
									                      <span class="sr-only">Toggle Dropdown</span>
									                    </button>
									                    <div class="dropdown-menu" role="menu">
									                      <a class="dropdown-item" href="#" @click="changeNominal()">Rupiah</a>
									                      <a class="dropdown-item" href="#" @click="changeNominal()">Persen (%)</a>
									                    </div>
									                  </div>
								                      <input type="number" class="form-control" required="" id="nominal" name="nominal" placeholder="Nominal" v-model="voucher.nominal" @keyup="limitValueNominal(voucher.nominal)">
									               </div>
									           </div>
											</div>	
										</div>
										<div class="col"></div>	
									</div>
									<div class="row">
										<div class="col-8">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Masa Berlaku <span class="text-danger">*</span></label>
								                   <input type="date" class="form-control" required="" id="masa_berlaku" name="masa_berlaku" placeholder="Jumlah" v-model="voucher.masa_berlaku">
									           </div>
											</div>	
										</div>
										<div class="col-4"></div>	
									</div>
									<div class="px-lg-2 mt-3">
										<button type="submit" class="btn btn-primary">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader3'

	import Swal from 'sweetalert2'
	import { ref, onMounted } from 'vue'
	import router from '@/router'
	import axios from 'axios'
	import { useRoute } from 'vue-router'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup(){
			const route = useRoute()
			const voucher_id = route.params.id

			const { voucher, labelNominal, getVoucher, updateDataVoucher } = useGetVoucher(voucher_id)

			const { changeNominal, limitValueNominal } = useInputPersen(voucher, labelNominal)

			onMounted(() => {
				getVoucher()
			})

			return {
				voucher,
				getVoucher,
				updateDataVoucher,
				labelNominal,
				changeNominal,
				limitValueNominal
			}
		}
	}


	function useGetVoucher (id) {
	  const voucher = ref([])
	  const labelNominal = ref('')
	  
	  const getVoucher = async () => {
	    let response = await axios.get(`api/voucher/getWhereId/${id}`)
	    voucher.value = response.data
	    voucher.value.masa_berlaku = voucher.value.masa_berlaku.slice(0, 10)

	    if (voucher.value.nominal <= 100) {
			labelNominal.value = 'Persen (%)'
		} else {
			labelNominal.value = 'Rp.'
		}

	  }

	  const updateDataVoucher = () => {
			axios.put(`api/voucher/update/${id}`, voucher.value)
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil mengedit data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})	
	    		console.log(response.data)
	    		router.push('/data-voucher')
	    	})
	    	.catch((error) => {
	    		Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal mengedit data',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})	
	    		console.log(error)
	    	})	
		}

	  return {
	    voucher,
	    getVoucher, 
	    updateDataVoucher,
	    labelNominal
	  }  
	}


	function useInputPersen (voucher, labelNominal) {
		const changeNominal = () => {
			if (labelNominal.value == 'Rp.') {
				labelNominal.value = 'Persen (%)'
				voucher.value.nominal = 0
			} else if (labelNominal.value == 'Persen (%)') {
				labelNominal.value = 'Rp.'
				voucher.value.nominal = 0
			}
		}

		const limitValueNominal = (value) => {
			console.log(`${voucher.value.nominal}`)
			if (labelNominal.value == 'Persen (%)' && value > 100) {
				voucher.value.nominal = 100
			} else if (labelNominal.value == 'Persen (%)' && value < 0) {
				voucher.value.nominal = 0
			}
		}

		return {
			changeNominal,
			limitValueNominal
		}
	}
	
</script>